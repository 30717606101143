/* ###### Plugins ####### */

@import "variables";

@import "plugins/accordion/accordion";
@import "plugins/chat/jquery.convform";
@import "plugins/datatable/dataTables.bootstrap5.min";
@import "plugins/datatable/jquery.dataTables.min";
@import "plugins/date-picker/spectrum";
@import "plugins/fancyuploder/fancy_fileupload";
@import "plugins/fullcalendar/fullcalendar";
@import "plugins/jquery-countdown/countdown";
@import "plugins/jquery-uislider/jquery-ui";
@import "plugins/jquery.flexdatalist/jquery.flexdatalist";
@import "plugins/lightgallery/gallery";
@import "plugins/owl-carousel/owl.carousel";
@import "plugins/primo-slider/primo-slider";
@import "plugins/pscrollbar/pscrollbar";
@import "plugins/select2/select2";
@import "plugins/smart-wizard/smart_wizard";
@import "plugins/smart-wizard/smart_wizard_theme_arrows";
@import "plugins/smart-wizard/smart_wizard_theme_circles";
@import "plugins/smart-wizard/smart_wizard_theme_dots";
@import "plugins/tabs/style";
@import "plugins/timeline/timeline";
@import "plugins/video/insideElementDemo";