/************ RTL ************/

.rtl {
  .timeline {
    .time-show {
      margin-left: -75px;
      margin-right: auto;
    }

    &:before {
      right: 50%;
      left: auto;
    }

    .timeline-icon {
      right: -54px;
      left: auto;
    }
  }

  .timeline-item {
    .timeline-desk .arrow {
      border-left: 8px solid #ffffff !important;
      border-right: 0 !important;
      right: -7px;
      left: auto;
    }

    &.alt {
      .timeline-desk {
        .arrow-alt {
          border-right: 8px solid #ffffff !important;
          border-left: 0 !important;
          right: auto;
          left: -7px;
        }

        .album {
          float: left;

          a {
            float: left;
            margin-left: 5px;
            margin-right: auto;
          }
        }
      }

      .timeline-icon {
        right: auto;
        left: -57px;
      }

      .panel {
        margin-right: 0;
        margin-left: 45px;
      }

      h4, p, .timeline-date {
        text-align: left;
      }
    }
  }

  .timeline-desk {
    .panel {
      margin-right: 45px;
      margin-left: auto;
    }

    .album a {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }
  }

  .timelineleft {
    &:before {
      right: 31px;
      left: auto;
    }

    > li {
      margin-left: 10px;
      margin-right: auto;

      > {
        .timelineleft-item {
          margin-right: 60px;
          margin-left: 15px;

          > .time {
            float: left;
          }
        }

        .fa, .glyphicon, .ion {
          right: 18px;
          left: auto;
        }
      }
    }
  }
}

/************ RTL ************/

/*-------- Timeline -------*/

.timeline {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin-bottom: 50px;
  position: relative;
  table-layout: fixed;
  width: 100%;

  .time-show {
    margin-bottom: 30px;
    margin-right: -75px;
    margin-top: 30px;
    position: relative;

    a {
      color: #ffffff;
    }
  }

  &:before {
    background-color: rgba(152, 166, 173, 0.3);
    bottom: 0px;
    content: "";
    left: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0;
  }

  .timeline-icon {
    -webkit-border-radius: 50%;
    background: #6481ae;
    border-radius: 50%;
    color: #ffffff;
    display: block;
    height: 21px;
    left: -54px;
    margin-top: -11px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 21px;

    i {
      color: #e0e8f5;
    }
  }

  .time-icon:before {
    font-size: 16px;
    margin-top: 5px;
  }
}

h3.timeline-title {
  color: #6481ae;
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.timeline-item {
  display: table-row;

  &:before {
    content: "";
    display: block;
    width: 50%;
  }

  .timeline-desk .arrow {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #ffffff !important;
    border-top: 8px solid transparent;
    display: block;
    height: 0;
    left: -7px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
    width: 0;
  }

  &.alt {
    &:after {
      content: "";
      display: block;
      width: 50%;
    }

    .timeline-desk {
      .arrow-alt {
        border-bottom: 8px solid transparent;
        border-left: 8px solid #ffffff !important;
        border-top: 8px solid transparent;
        display: block;
        height: 0;
        left: auto;
        margin-top: -10px;
        position: absolute;
        right: -7px;
        top: 50%;
        width: 0;
      }

      .album {
        float: right;
        margin-top: 20px;

        a {
          float: right;
          margin-left: 5px;
        }
      }
    }

    .timeline-icon {
      left: auto;
      right: -57px;
    }

    &:before {
      display: none;
    }

    .panel {
      margin-left: 0;
      margin-right: 45px;

      .panel-body p + p {
        margin-top: 10px !important;
      }
    }

    h4, p, .timeline-date {
      text-align: right;
    }
  }
}

.timeline-desk {
  display: table-cell;
  vertical-align: top;
  width: 50%;

  h4 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  .panel {
    background: #ffffff;
    display: block;
    margin-bottom: 5px;
    margin-left: 45px;
    position: relative;
    text-align: left;
    padding: 20px;
  }

  h5 span {
    color: #797979;
    display: block;
    font-size: 12px;
    margin-bottom: 4px;
  }

  p {
    color: #101010;
    font-size: 14px;
    margin-bottom: 0;
  }

  .album {
    margin-top: 12px;

    a {
      float: left;
      margin-right: 5px;
    }

    img {
      height: 50px;
      width: auto;
      border-radius: 3px;
    }
  }

  .notification {
    background: none repeat scroll 0 0 #ffffff;
    margin-top: 20px;
    padding: 8px;
  }
}

/** timeline left **/

.timelineleft {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: rgba(152, 166, 173, 0.3);
    left: 31px;
    margin: 0;
    border-radius: 2px;
  }

  > li {
    position: relative;
    margin-right: 10px;
    margin-bottom: 1.5rem;
  }
}

.timelinelft > li:before {
  content: " ";
  display: table;
}

.timelineleft > {
  li:after {
    content: " ";
    display: table;
  }

  .timeleft-label > span {
    font-weight: 600;
    padding: 5px;
    display: inline-block;
    background-color: #fff;
    color: #fff;
    border-radius: 4px;
  }

  li {
    &:after {
      clear: both;
    }

    > .timelineleft-item {
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 0 1px rgba(61, 119, 180, 0.12), 0 8px 16px 0 rgba(91, 139, 199, 0.24);
      border-radius: 8px;
      margin-top: 0;
      background: #fff;
      color: #444;
      margin-left: 60px;
      margin-right: 15px;
      padding: 0;
      position: relative;
      overflow: hidden;

      > {
        .time {
          color: #999;
          float: right;
          padding: 10px;
          font-size: 12px;
        }

        .timelineleft-header {
          margin: 0;
          color: #555;
          border-bottom: 1px solid #e0e8f5;
          padding: .75rem 1.25rem;
          font-size: 16px;
          line-height: 1.1;

          > a {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}

.timelineleft-body img {
  width: 150px;
  height: 100px;
  margin: 5px;
  border-radius: 5px;
}

.timelineleft > li > .timelineleft-item > {
  .timelineleft-body, .timelineleft-footer {
    padding: .75rem 1.25rem;
  }
}

.timelineleft-body {
  font-size: 14px;
}

.timelineleft > li > {
  .fa, .glyphicon, .ion {
    width: 30px;
    height: 30px;
    font-size: 15px;
    line-height: 30px;
    position: absolute;
    color: #fff;
    background: #d2d6de;
    border-radius: 8px;
    text-align: center;
    left: 18px;
    top: 0;
  }
}